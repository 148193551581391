import { addHours, isAfter, subWeeks } from '@artegeie/date';
import Head from 'next/head';
import pickBy from 'ramda/src/pickBy';
import { type ReactElement } from 'react';
import { env } from '@replay/env-generator';
import { code, credits } from '@replay/types/Credit';
import { type Locale } from '@replay/i18n/types/locale';
import { type Teaser } from '@replay/types/Teaser';
import type { Zone } from '@replay/types/Zone';
import { getMamiImage } from '../getMamiImage';
import { getCategoryFromParent, ParentTree } from './parent';
import { formatInTimeZone } from 'date-fns-tz';

type JsonLdProps = { data: unknown };

export const JsonLd = ({ data }: JsonLdProps): ReactElement => {
    return (
        <Head>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
        </Head>
    );
};

export const socialPageUrlsPerLocale: { [k: string]: string[] } = {
    fr: ['https://twitter.com/artefr', 'https://www.facebook.com/artetv/'],
    de: ['https://twitter.com/artede', 'https://de-de.facebook.com/artede/'],
    en: ['https://twitter.com/arteen', 'https://www.facebook.com/ARTEenglish/'],
    es: ['https://twitter.com/arteesp', 'https://www.facebook.com/ARTEespanol/'],
    pl: ['https://twitter.com/artepl', 'https://www.facebook.com/ARTEpopolsku/'],
};

export const JsonLdOrganisation = ({ url, language }: { url: string; language: Locale }): ReactElement => {
    const sameAs = socialPageUrlsPerLocale[language] || [];
    return (
        <JsonLd
            data={{
                '@context': 'http://schema.org',
                '@type': 'Organization',
                url: url,
                sameAs,
                name: 'ARTE',
                logo: `${env.NEXT_PUBLIC_CDN_URL}/favicons/favicon-194x194.png`,
                contactPoint: {
                    '@type': 'ContactPoint',
                    telephone: '+33-388142255',
                    contactType: 'customer support',
                },
            }}
        />
    );
};

type JsonLdWebSiteProps = {
    url: string;
    language: Locale;
};

export const JsonLdWebSite = ({ url, language }: JsonLdWebSiteProps): ReactElement => {
    return (
        <JsonLd
            data={{
                '@context': 'http://schema.org',
                '@type': 'WebSite',
                url: url,
                potentialAction: {
                    '@type': 'SearchAction',
                    'query-input': 'required name=search_term_string',
                    target: `/${language}/search/?q={search_term_string}&page=1`,
                },
            }}
        />
    );
};

type Item = {
    title: string;
    url: string;
    image?: string[];
    logo?: string | undefined;
};

type JsonLdBreadcrumbListProps = {
    items: Item[];
};

export const JsonLdBreadcrumbList = ({ items }: JsonLdBreadcrumbListProps): ReactElement => {
    return (
        <JsonLd
            data={{
                '@context': 'http://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: items.map(({ title, url, image, logo }, index) => {
                    const item = {
                        id: url,
                        name: title,
                        image,
                        logo,
                    };
                    return {
                        '@type': 'ListItem',
                        position: index + 1,
                        item: pickBy((v) => !!v, item),
                    };
                }),
            }}
        />
    );
};

export const buildValidJsonLd = <T,>(jsonld: Partial<T>) => {
    const validJsonLd: Partial<T> = (Object.keys(jsonld) as Array<keyof T>)
        .filter((key) => Boolean(jsonld[key]))
        .reduce((acc, key) => ({ ...acc, [key]: jsonld[key] }), {});

    return validJsonLd;
};

export const isProgramAiring = (program: Teaser): boolean => {
    if (!program) {
        return false;
    }
    const { availability, publishEnd } = program;
    const endRight = subWeeks(publishEnd, 3);
    const hasRightEnded = isAfter(new Date(), endRight);
    const isAvailableOrAvailableSoon = availability && !isAfter(availability.start, addHours(new Date(), 24));

    return (isAvailableOrAvailableSoon && !hasRightEnded) || false;
};
type Person = {
    '@type': string;
    name: string;
};
type Program = {
    '@type': string;
    name: string;
    actor: Person[] | null;
    description: string | null | undefined;
    image: string[];
    duration: string | null;
    director: Person[] | null;
    expires: string | null;
    genre: string[] | null;
    publication:
        | {
              '@type': 'BroadcastEvent';
              isLiveBroadcast: true;
              startDate: string | null;
              endDate: string | null;
          }
        | undefined;
    potentialAction:
        | {
              '@type': 'SeekToAction';
              target: string | null;
              'startOffset-input': 'required name=seek_to_second_number';
          }
        | undefined;
};

const getPersonsFromCreditsByCode = (credits: credits | null | undefined, code: code) => {
    if (!credits) {
        return null;
    }
    const credit = credits.find((credit) => credit.code === code);
    return credit && credit.values ? credit.values.map((value) => ({ '@type': 'Person', name: value })) : null;
};

export const getProgramObject = (
    teaser: Teaser,
    locale: Locale,
    zones: Zone[],
    mamiBaseUrl: string,
    url: string,
    parent?: ParentTree,
): Partial<Program> => {
    const { title, subtitle, duration, shortDescription, availability, programId, genre, credits } = teaser;
    const startDate = availability
        ? formatInTimeZone(availability.start, env.NEXT_PUBLIC_TIMEZONE, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
        : null;
    const endDate =
        availability && availability.end
            ? formatInTimeZone(availability.end, env.NEXT_PUBLIC_TIMEZONE, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
            : null;
    const isLiveBroadcast = availability ? ['LIVESTREAM_TV', 'LIVESTREAM_WEB'].includes(availability.type) : false;
    const category = getCategoryFromParent(parent);
    const typeValue = category && category.page === 'SER' ? 'Episode' : 'Movie';

    const director = getPersonsFromCreditsByCode(credits, 'REA');
    const actor = getPersonsFromCreditsByCode(credits, 'ACT');

    const jsonld: Partial<Program> = {
        '@type': typeValue,
        name: `${title}${subtitle ? ` - ${subtitle}` : ''}`,
        actor: actor,
        description: shortDescription,
        image: [
            getMamiImage({
                locale,
                programId,
                width: 1920,
                height: 1080,
                text: true,
                zones,
                mamiBaseUrl,
                watermark: true,
            }),
            getMamiImage({
                locale,
                programId,
                width: 940,
                height: 940,
                text: true,
                zones,
                mamiBaseUrl,
                watermark: true,
            }),
        ],
        duration: duration ? `PT${duration}S` : null,
        director: director,
        expires: endDate,
        genre: genre && genre.label ? [genre.label] : null,
        potentialAction: {
            '@type': 'SeekToAction',
            target: `${url}?t={seek_to_second_number}`,
            'startOffset-input': 'required name=seek_to_second_number',
        },
        publication: isLiveBroadcast
            ? {
                  '@type': 'BroadcastEvent',
                  isLiveBroadcast,
                  startDate,
                  endDate,
              }
            : undefined,
    };

    return buildValidJsonLd<Program>(jsonld);
};

type PartObject = {
    '@type': 'Clip';
    name: string;
    startOffset: number;
    endOffset: number;
    url: string;
};
type VideoObject = {
    uploadDate: string | null;
    embedURL: string | null;
    thumbnailUrl: string[];
    name: string;
    description: string | null | undefined;
    hasPart?: PartObject[];
};

function makeChaptersData(
    chapters: Teaser['chapters'],
    url: Teaser['url'],
    duration: Teaser['duration'],
): PartObject[] | null {
    return (
        chapters?.map((chapter, i, chapters) => {
            const nextChapterTimecode = i !== chapters.length - 1 ? chapters[i + 1].timecode : (duration as number);
            return {
                '@type': 'Clip',
                name: chapter.title,
                startOffset: chapter.timecode,
                url: `${url}?t=${chapter.timecode}`,
                endOffset: nextChapterTimecode,
            };
        }) ?? null
    );
}

const getVideoObject = (
    { title, subtitle, shortDescription, availability, programId, chapters, duration, url }: Teaser,
    locale: Locale,
    zones: Zone[],
    mamiBaseUrl: string,
): Partial<VideoObject> => {
    const startDate = availability
        ? formatInTimeZone(availability.start, env.NEXT_PUBLIC_TIMEZONE, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
        : null;

    const embedURL = `https://www.arte.tv/embeds/${locale}/${programId}`;
    const jsonld: VideoObject = {
        name: `${title}${subtitle ? ` - ${subtitle}` : ''}`,
        description: shortDescription,
        embedURL,
        uploadDate: startDate,
        thumbnailUrl: [
            getMamiImage({
                locale,
                programId,
                width: 1920,
                height: 1080,
                text: true,
                zones,
                mamiBaseUrl,
                watermark: true,
            }),
            getMamiImage({
                locale,
                programId,
                width: 940,
                height: 940,
                text: true,
                zones,
                mamiBaseUrl,
                watermark: true,
            }),
        ],
    };
    const chaptersData = makeChaptersData(chapters, url, duration);
    if (chaptersData) jsonld.hasPart = chaptersData;

    return buildValidJsonLd<VideoObject>(jsonld);
};

export const JsonLdProgram = ({
    parent,
    program,
    locale,
    zones,
    mamiBaseUrl,
    url,
}: {
    parent?: ParentTree;
    program: Teaser | undefined;
    locale: Locale;
    zones: Zone[];
    mamiBaseUrl: string;
    url: string;
}): ReactElement | null => {
    if (!program || !isProgramAiring(program)) {
        return null;
    }
    const programObject = getProgramObject(program, locale, zones, mamiBaseUrl, url, parent);
    const videoObject = getVideoObject(program, locale, zones, mamiBaseUrl);

    return (
        <JsonLd
            data={{
                '@context': 'http://schema.org',
                ...programObject,
                video: {
                    '@context': 'http://schema.org',
                    '@type': 'VideoObject',
                    ...videoObject,
                },
            }}
        />
    );
};
